/**
* Copyright (C) 2016-present The ISLE Authors
*
* The isle-dashboard program is free software: you can redistribute it and/or modify
* it under the terms of the GNU Affero General Public License as
* published by the Free Software Foundation, either version 3 of the
* License, or (at your option) any later version.
*
* This program is distributed in the hope that it will be useful,
* but WITHOUT ANY WARRANTY; without even the implied warranty of
* MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
* GNU Affero General Public License for more details.
*
* You should have received a copy of the GNU Affero General Public License
* along with this program.  If not, see <https://www.gnu.org/licenses/>.
*/

// MODULES //

import * as types from 'constants/action_types.js';


// VARIABLES //

const initialState = {
	message: '',
	level: '',
	position: 'tl',
	children: null,
	time: null,
	autoDismiss: 5
};


// EXPORTS //

export default function namespace( state = initialState, action ) {
	switch ( action.type ) {
	case types.ADD_NOTIFICATION: {
		return Object.assign({}, initialState, {
			...action.payload,
			time: new Date()
		});
	}
	case types.ADD_ERROR_NOTIFICATION: {
		return {
			title: 'Error encountered',
			message: action.payload,
			level: 'error',
			position: 'tl',
			autoDismiss: 5,
			time: new Date()
		};
	}
	default:
		return state;
	}
}
